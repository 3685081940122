var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',{staticClass:"justify-content-end"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0",attrs:{"cols":"12","md":"3"}},[_c('b-button-group',[_c('b-dropdown',{attrs:{"right":"","variant":"primary","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',{staticClass:"mr-2"},[_vm._v("Create")]),_c('feather-icon',{attrs:{"size":"16","icon":"MoreVerticalIcon"}})],1)]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":{ name: 'apps-transactions-cashbank-add-transfer' }}},[_c('span',[_vm._v(_vm._s(_vm.$t("globalSingular.transfer")))])]),_c('b-dropdown-group',{attrs:{"header":_vm.$t('globalSingular.cash')}},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'apps-transactions-cashbank-add-cash-in',
                  params: { transactionTypeCode: 'CI' },
                }}},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("globalSingular.income")))])]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'apps-transactions-cashbank-add-cash-out',
                  params: { transactionTypeCode: 'CO' },
                }}},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("globalSingular.expense")))])])],1),_c('b-dropdown-group',{attrs:{"header":_vm.$t('globalSingular.bank')}},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'apps-transactions-cashbank-add-bank-in',
                  params: { transactionTypeCode: 'BI' },
                }}},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("globalSingular.income")))])]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'apps-transactions-cashbank-add-bank-out',
                  params: { transactionTypeCode: 'BO' },
                }}},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("globalSingular.expense")))])])],1)],1)],1)],1)],1)],1),_c('b-table',{ref:"refListTable",staticClass:"position-relative",attrs:{"id":"refListTable","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.itemLists,"responsive":"","primary-key":"id","show-empty":"","busy":_vm.isBusy,"empty-text":_vm.$t('globalSingular.noMatching'),"sort-desc":_vm.isSortDirDesc},on:{"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2 d-flex flex-column justify-content-center"},[_c('div',{staticClass:"text mb-1"},[_c('b-spinner',{staticClass:"align-middle"})],1),_c('strong',[_vm._v(_vm._s(_vm.$t("globalActions.fetchingDataAPI")))])])]},proxy:true},{key:"head()",fn:function(ref){
                var field = ref.field;
return [_c('table-search-column',{attrs:{"field":field,"fields":_vm.tableColumns,"focused-field":_vm.focusedSearchField},on:{"set-focused-field":function (value) { return _vm.focusedSearchField = value; },"search-data":function (params) { return _vm.searchData(params); }}})]}},{key:"cell(codes)",fn:function(ref){
                var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bolder",attrs:{"to":{
          name: ("" + (item.transaction_type_code === 'TF'
              ? 'apps-transactions-cashbank-detail-transfer'
              : 'apps-transactions-cashbank-detail')),
          params: {
            id: item.id,
            transactionTypeCode: item.transaction_type_code,
          },
        }}},[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.code)+" ")])])]}},{key:"cell(label)",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.contact ? item.contact.label : "Transfer")+" ")]),_c('small',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.remark)+" "+_vm._s(_vm.$t("globalSingular.via"))+" "),_c('span',{staticClass:"font-weight-bolder text-primary"},[_vm._v(_vm._s(_vm.companyActive.plan_id > 2 ? item.office ? item.office.label : item.account.account_name : item.account.account_name))])])]}},{key:"cell(cashbank)",fn:function(ref){
        var item = ref.item;
return [(
          item.transaction_type_code === 'TF' && item.is_transfer_verified
        )?_c('div',[(item.amount > 0)?_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.cashbankto === null ? "-" : item.cashbankto.label)+" ")]):_vm._e()]):_c('div',[(item.amount < 0 && item.transaction_type_code === 'TF')?_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.cashbank === null ? "-" : item.cashbank.label)+" ")]):_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.cashbank === null ? "-" : item.cashbank.label)+" ")])])]}},{key:"cell(transaction_type)",fn:function(ref){
        var item = ref.item;
return [(
          item.transaction_type_code === 'TF' && item.is_transfer_verified
        )?_c('div',[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[(item.cashbankto.is_bank)?_c('div',[_vm._v("Bank Transfer In")]):_c('div',[_vm._v("Cash Transfer In")])])]):(
          item.transaction_type_code === 'TF' &&
          (item.is_transfer_verified === false ||
            item.is_transfer_verified === null)
        )?_c('div',[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[(item.cashbank.is_bank)?_c('div',[_vm._v("Bank Transfer Out")]):_c('div',[_vm._v("Cash Transfer Out")])])]):_c('div',[_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.transactionType.remark_eng)+" ")])])]}},{key:"cell(amount)",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:[
          'font-weight-bolder',
          'd-block',
          'text-nowrap',
          item.amount > 0 ? 'text-success' : 'text-danger' ]},[(item.amount > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.amount, false))+" ")]):_c('div',[_vm._v(" ("+_vm._s(_vm.formatCurrency(item.amount * -1, false))+") ")])])]}},{key:"cell(tags)",fn:function(ref){
        var item = ref.item;
return [((item.tags || []).length > 0)?_c('div',{staticClass:"d-flex"},_vm._l((item.tags),function(tag,index){return _c('b-badge',{key:index,attrs:{"pill":"","variant":"light-primary"}},[_vm._v(_vm._s(tag.name))])}),1):_vm._e()]}},{key:"cell(audit)",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bolder d-block text-nowrap"},[_vm._v(" "+_vm._s(item.updated_process)+" By "+_vm._s(item.getUser.user_name)+" ")]),_c('small',{staticClass:"font-italic"},[_vm._v(_vm._s(new Date(item.updated_at).toDateString()))])]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("globalSingular.showing"))+" "+_vm._s(_vm.dataMeta.from)+" "+_vm._s(_vm.$t("globalSingular.to"))+" "+_vm._s(_vm.dataMeta.to)+" "+_vm._s(_vm.$t("globalSingular.of"))+" "+_vm._s(_vm.dataMeta.of)+" "+_vm._s(_vm.$t("globalSingular.entries")))])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalList,"per-page":"15","first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","aria-controls":"refListTable"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }